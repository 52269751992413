/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    code: "code",
    h3: "h3",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "The Scroll component scrolls the carousel to the arbitrary position without the Transition component. This component moves the carousel by ", React.createElement(_components.code, null, "requestAnimationFrame"), ". The Drag component uses this in the drag free mode."), "\n", React.createElement(_components.h2, {
    id: "methods",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#methods",
    "aria-label": "methods permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Methods"), "\n", React.createElement(_components.h3, {
    id: "scroll",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scroll",
    "aria-label": "scroll permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "scroll()")), "\n", React.createElement(Type, {
    code: 'scroll( position: number, duration?: number, snap?: boolean, callback?: AnyFunction ): void'
  }), "\n", React.createElement(_components.p, null, "Scrolls the carousel to the specified destination.\nUnlike ", React.createElement(_components.code, null, "Controller#scroll()"), ", this method does not update active and previous indices."), "\n", React.createElement(Params, {
    params: [['destination', 'A position or an index to scroll to. The `useIndex` must be `true` to specify the index.'], ['duration', 'Optional. Specifies the scroll duration. If omitted, it is computed by distance.'], ['snap', 'Optional. Whether to snap the closest slide or not.'], ['callback', 'Optional. A callback function invoked after scroll ends.']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "cancel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cancel",
    "aria-label": "cancel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "cancel()")), "\n", React.createElement(Type, {
    code: 'cancel(): void'
  }), "\n", React.createElement(_components.p, null, "Cancels scrolling and emits the ", React.createElement(_components.code, null, "scrolled"), " event."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
